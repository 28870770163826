import logoPath from "@/img/logo.svg";
import { initDictionary } from "@cospex/client/helpers/dictionary";
import GlobalProvider from "@cospex/client/provider";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import { Routes } from "@generouted/react-router/lazy";
import "intl-tel-input/build/css/intlTelInput.css";
import { createRoot } from "react-dom/client";

import theme from "./Theme";

const container = document.getElementById("app")!;

const languages = [
  { value: "en", label: "EN 🇬🇧" },
  { value: "fr", label: "FR 🇫🇷" },
  { value: "it", label: "IT 🇮🇹" },
  { value: "de", label: "DE 🇩🇪" },
  { value: "es", label: "ES 🇪🇸" },
];

initDictionary(languages);

createRoot(container).render(
  <GlobalProvider
    theme={theme}
    config={{
      logo: {
        src: logoPath,
        sx: {
          fontSize: "1rem",
        },
      },
      languages,
    }}
  >
    <Routes />
  </GlobalProvider>
);
